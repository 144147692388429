import React, {useEffect, useState} from 'react';
import etoileVide from "../../../../../assets/etoiles/2.png"
import etoileMoitie from "../../../../../assets/etoiles/3.png"
import etoilepleine from "../../../../../assets/etoiles/1.png"
import {isEmpty} from "../../../../../Utils/Utils";
import './star.css'


const Star = ({score}) => {


    const [number, setNumber] = useState()

    const star = [
        [etoileVide,etoileVide,etoileVide,etoileVide,etoileVide],
        [etoileMoitie,etoileVide,etoileVide,etoileVide,etoileVide],
        [etoilepleine,etoileVide,etoileVide,etoileVide,etoileVide],
        [etoilepleine, etoileMoitie,etoileVide,etoileVide,etoileVide],
        [etoilepleine, etoilepleine,etoileVide,etoileVide,etoileVide],
        [etoilepleine, etoilepleine, etoileMoitie,etoileVide,etoileVide],
        [etoilepleine, etoilepleine, etoilepleine,etoileVide,etoileVide],
        [etoilepleine, etoilepleine, etoilepleine, etoileMoitie,etoileVide],
        [etoilepleine, etoilepleine, etoilepleine, etoilepleine,etoileVide],
        [etoilepleine, etoilepleine, etoilepleine, etoilepleine, etoileMoitie],
        [etoilepleine, etoilepleine, etoilepleine, etoilepleine, etoilepleine],

    ]


    useEffect(() => {

        const n = parseInt(score)
        if (n <= 5) {
            setNumber(0)
        }
        if (n > 5 && n <= 15) {
            setNumber(1)
        }
        if (n > 15 && n <= 25) {
            setNumber(2)
        }
        if (n > 25 && n <= 35) {
            setNumber(3)
        }
        if (n > 35 && n <= 45) {
            setNumber(4)
        }
        if (n > 45 && n <= 55) {
            setNumber(5)
        }
        if (n > 55 && n <= 65) {
            setNumber(6)
        }
        if (n > 65 && n <= 75) {
            setNumber(7)
        }
        if (n > 75 && n <= 85) {
            setNumber(8)
        }
        if (n > 85 && n <= 95) {
            setNumber(9)
        }
        if (n > 95) {
            setNumber(10)
        }

    }, [])



    return (
        <div className="star">

            {

                !isEmpty(star[number]) && (
                    star[number].map((s,index) => {

                            return <img key={index} className="star_img" src={s} alt="ok"/>

                    }))

            }

        </div>
    );
};

export default Star;