import { createSlice } from '@reduxjs/toolkit';

export const disponibilitySlice = createSlice({
  name: 'disponibility',
  initialState: [],
  reducers: {
    updateDisponibility: (state, action) => ({ ...state, ...action.payload }),

    defaultDisponibility:(state, action) => {
      return[]
    }
  },
});

export const { updateDisponibility,defaultDisponibility } = disponibilitySlice.actions;
