import React from "react";
import { IonSkeletonText } from "@ionic/react";

const SkeletonDayPlan = () => {
  return (

    
    <div className="ion-padding custom-skeleton trainingDay">
      <IonSkeletonText
        className="trainingDay_subtitle"
        animated
        style={{
          width: "70%",
          height: "40px",
          margin: "auto",
        }}
      />
      <IonSkeletonText
        animated
        style={{
          width: "70%",
          height: "40px",
          margin: "10px auto 10px auto",
        }}
      />
      <IonSkeletonText
        animated
        style={{
          width: "70%",
          height: "40px",
          margin: "auto",
        }}
      />

  
      <ul>
        <IonSkeletonText
          animated
          style={{
            width: "100%",
            height: "40px",
            margin: "30px 0px 0px 0px",
          }}
        />
      </ul>

      <ul className="trainingDay_data_box">
        <li>
          <IonSkeletonText
            animated
            style={{
              width: "100%",
              height: "100px",
              margin: "50px auto 10px auto",
            }}
          />
        </li>
        <li>
          <IonSkeletonText
            animated
            style={{
              width: "100%",
              height: "100px",
              margin: "10px auto 10px auto",
            }}
          />
        </li>
        <li>
          <IonSkeletonText
            animated
            style={{
              width: "100%",
              height: "100px",
              margin: "auto",
            }}
          />
        </li>
      </ul>
    </div>
  );
};

export default SkeletonDayPlan;
