import { createSlice } from '@reduxjs/toolkit';

export const updateFormSlice = createSlice({
  name: 'updateForm',
  initialState: [
    {
      updateRegister: false,
      updateTypeOfRun: false,
      updateFormDisponibility: false,
    },
  ],
  reducers: {
    updateForm: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { updateForm } = updateFormSlice.actions;
