import { createSlice } from "@reduxjs/toolkit";


// Describe the Google Play / AppStore as a react store
//   - packages: list of available packages to buy
//   - subscriptions: list of available subscriptions type
//   - ready: has the store been setup since startup, use .usable instead to also check if you are not processing
//   - transacting: transaction in progress, you should probably block the whole UI, or at least show it is procesing
//   - usable: can we actually use the store
// For subscriptions, allow subscribing if and only if both ok
//   - flit backend doesn't show us as subscribed
//   - the plugin show the subscription as ready to be bought, not owned or processing etc : .state === 'valid'
//     see https://github.com/j3k0/cordova-plugin-purchase/blob/master/doc/api.md#life-cycle
// Implement flit backend states however you want
export const shopSlice = createSlice({
  name: "shop",
  initialState: { packages: {}, subscriptions: {}, ready: false, transacting: false, usable: false },
  reducers: {
    setShop: (state, action) => {
      const r = {...state, ...action.payload};
      r.usable = r.ready && !r.transacting;
      return r;
    },
  },
});

export const { setShop } = shopSlice.actions;

