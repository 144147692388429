import {
  IonContent,
  IonInput,
  IonPage,
  IonItem,
  IonText,
  IonHeader,

  IonToolbar,
  IonImg, IonIcon,
} from "@ionic/react";
import logo from "../../../../../assets/FormulaireAssets/7.png";
import React, { useState } from "react";
import {useDispatch } from "react-redux";
import { useHistory } from "react-router";
import "./howManyTimes.css";
import FooterForm from "../../../../Reusable/FooterForm";
import {updateDisponibility} from "../../../../../redux/slices/disponibility/disponibility";
import {addOutline, remove} from "ionicons/icons";


const HowManyTimes = () => {
  const dispatch = useDispatch()
  const [
    practice_running_time_available_by_week,
    setPractice_running_time_available_by_week,
  ] = useState(2);
  const history = useHistory();

  const handleCountUp = () => {
    setPractice_running_time_available_by_week(
      practice_running_time_available_by_week + 1
    );
    if (practice_running_time_available_by_week >= 7) {
      return setPractice_running_time_available_by_week(
        practice_running_time_available_by_week
      );
    }
  };
  const handleCountDown = () => {
    setPractice_running_time_available_by_week(
      practice_running_time_available_by_week - 1
    );
    if (practice_running_time_available_by_week <= 1) {
      return setPractice_running_time_available_by_week(
        practice_running_time_available_by_week
      );
    }
  };

  const handleSubmit = () => {
    dispatch(updateDisponibility({numberOfRaces : practice_running_time_available_by_week}))
    history.push(
      `/disponibilityForm`
    );
  };


  return (
    <IonPage>

      <IonContent className={'objectif content_form'}>
        <div className="howManyTimes div_principal">
          <div className="howManyTimes_content">
            <IonImg src={logo} className="howManyTimes_logo" />
            <div className={"howManyTimes_title_box"}>
              <h1 className={"howManyTimes_title_vert title_form_fredoka"} style={{textAlign: "center"}}>Mon rythme</h1>
              <h1 className={"howManyTimes_title_blue title_form_fredoka"}>d’entraînement</h1>
            </div>

            <IonText>
              <h5 className="howManyTimes_subtitle">
                Combien de séances tu veux faire par semaine ?
              </h5>
            </IonText>

            <div className="howManyTimes_globalButton">
              <div
                className="howManyTimes_button"
                onClick={handleCountDown}
              >
                <IonIcon icon={remove}/>
              </div>
                <input className="howManyTimes_input" readonly value={practice_running_time_available_by_week} />
              <div
                className="howManyTimes_button"
                onClick={handleCountUp}
              >
                <IonIcon icon={addOutline}/>
              </div>
            </div>
            <IonText>
              <p className="howManyTimes_subtitle_last">
                ⚠️ Tu pourras modifier le nombre d’entraînements au fil de ta
                progression et de tes envies.
              </p>
            </IonText>
          </div>
        </div>
        <FooterForm text={"Suivant"} onClick={handleSubmit}/>
      </IonContent>
    </IonPage>
  );
};

export default HowManyTimes;
