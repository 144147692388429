import {
    IonCheckbox,
    IonLabel,
  } from "@ionic/react";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { updateDisponibility } from "../../redux/slices/disponibility/disponibility";
  import "./disponibility.css";
  import moment from "moment";
  
  const Dispo = ({disponibility,setDisponibility}) => {
    const dispatch = useDispatch();

    
   const temps = moment().format("dddd")



    const handleChecked = (e) => {
      setDisponibility({
        ...disponibility,
        [e.target.name]: e.target.checked,
      });

    };

    
  
    return (
     
          <div className="disponibility_modal">
            <ul className="disponibility_list">
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">L</IonLabel>
                <span
                  className={
                    disponibility.monday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    type="checkbox"
                    disabled={moment().day() > 1}

                    name="monday"
                    className={
                      disponibility.monday ?
                          "disponibility_list_item_checkbox_checked":
                          "disponibility_list_item_checkbox"
                    }

                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">M</IonLabel>
                <span
                  className={
                    disponibility.tuesday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    disabled={moment().day() > 2}

                    type="checkbox"
                    value={disponibility.tuesday}
                    name="tuesday"
                    className={
                        disponibility.tuesday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">M</IonLabel>
                <span
                  className={
                    disponibility.wednesday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                      disabled={moment().day() > 3}

                      onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    type="checkbox"
                    name="wednesday"
                    className={
                        disponibility.wednesday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">J</IonLabel>
                <span
                  className={
                    disponibility.thursday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    type="checkbox"
                    disabled={moment().day() > 4}

                    name="thursday"
                    className={
                        disponibility.thursday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">V</IonLabel>
                <span
                  className={
                    disponibility.friday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    disabled={moment().day() > 5}

                    type="checkbox"
                    name="friday"
                    className={
                        disponibility.friday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">S</IonLabel>
                <span
                  className={
                    disponibility.saturday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    type="checkbox"
                    disabled={moment().day() > 6}

                    name="saturday"
                    className={
                        disponibility.saturday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
              <li className="disponibility_list_item">
                <IonLabel className="disponibility_list_label">D</IonLabel>
                <span
                  className={
                    disponibility.sunday
                      ? "disponibility_checkbox_box_checked"
                      : "disponibility_checkbox_box"
                  }
                >
                  <IonCheckbox
                    onIonChange={(e) => {
                      handleChecked(e);
                    }}
                    type="checkbox"
                    name="sunday"
                    className={
                        disponibility.sunday ?
                            "disponibility_list_item_checkbox_checked":
                            "disponibility_list_item_checkbox"
                    }


                  />
                </span>
              </li>
            </ul>
          </div>
     
    );
  };
  
  export default Dispo;
  