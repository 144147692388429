import React, {useState} from 'react';
import {
    IonButton,
    IonContent,
    IonHeader, IonIcon,

    IonPage,
    IonToolbar,

} from "@ionic/react";
import FooterForm from "../../../../../../Reusable/FooterForm";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import './question.css'
import axios from "axios";
import logo from "../../../../../../../assets/FormulaireAssets/10.png";
import {addOutline, remove} from "ionicons/icons";

const Question1 = () => {

    const [countNumber, setCountNumber] = useState(1)
    const [countTimes, setCountTimes] = useState(1)
    const [countDistance, setCountDistance] = useState(1)
    const user = useSelector((state) => state.currentUser)
    const history = useHistory()
    const novice = useSelector(state => state.novice)


    const handleCountUpNumber = () => {
        setCountNumber(
            countNumber + 1
        );
        if (countNumber >= 14) {
            return setCountNumber(
                countNumber
            );
        }
    };
    const handleCountDownNumber = () => {
        setCountNumber(
            countNumber - 1
        );
        if (countNumber <= 1) {
            return setCountNumber(
                countNumber
            );
        }
    };
    const handleCountUpTime = () => {
        setCountTimes(
            countTimes + 1
        );
        if (countTimes >= 14) {
            return setCountTimes(
                countTimes
            );
        }
    };
    const handleCountDownTime = () => {
        setCountTimes(
            countTimes - 1
        );
        if (countTimes <= 1) {
            return setCountTimes(
                countTimes
            );
        }

    };



    const handleSubmit = () => {

        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/noviceinfo/create`,
                {
                    debug: false,
                    data: {
                        userId: user.id,
                        distance: parseInt(countDistance),
                        typeId: novice.typeId,
                        time: countTimes,
                        number: countNumber

                    },
                    args: {},
                },
                {
                    headers: {
                        Authorization: user.token,
                    },
                }
            )
            .then((res) => {

                if (res.status === 200) {
                    history.push("/disponibilityWeek")
                }
            })
            .catch((err) => console.error(err));

    }

    return (
        <IonPage>

            <IonContent className={"objectif content_form"}>
                <div className="noviceLevel_question">
                    <img src={logo} alt={"logo"}/>
                    <div className={"noviceLevel_title_box"}>
                        <h1 className={"noviceLevel_title_vert title_form_fredoka"} style={{textAlign: "center"}}>Mes habitudes</h1>
                        <h1 className={"noviceLevel_title_blue title_form_fredoka"}>de course</h1>
                    </div>
                    <div className="noviceLevel_question_content">
                        <h5 className="noviceLevel_question_title"> Combien de fois as-tu l'habitude de courir par
                            semaine ?</h5>
                        <div className="noviceLevel_question_Content">
                            <div className="howManyTimes_globalButton">
                                <div
                                    className="howManyTimes_button"
                                    onClick={handleCountDownNumber}
                                >
                                    <IonIcon icon={remove}/>
                                </div>

                                <input className="noviceLevel_input" value={`${countNumber} fois`}
                                       placeholder={countNumber}>

                                </input>

                                <div
                                    className="howManyTimes_button"
                                    onClick={handleCountUpNumber}
                                >
                                    <IonIcon icon={addOutline}/>
                                </div>
                            </div>


                        </div>

                        <h5 className="noviceLevel_question_title">Quelle distance as-tu l'habitude de courir par
                            semaine ?</h5>
                        <div className="noviceLevel_question_Content">
                            <input type="number" className="noviceLevel_input"
                                   onChange={(e) => {
                                       setCountDistance(e.target.value);
                                   }}
                                   placeholder={`5 km`}>

                            </input>

                        </div>


                        <h5 className="noviceLevel_question_title">Cela représente combien d'heures de course par semaine ?</h5>
                        <div className="noviceLevel_question_Content">
                            <div className="howManyTimes_globalButton">
                                <div
                                    className="howManyTimes_button"
                                    onClick={handleCountDownTime}
                                >
                                    <IonIcon icon={remove}/>
                                </div>

                                <input className="noviceLevel_input" value={`${countTimes} h`}
                                       placeholder={`${countTimes} h`}>

                                </input>

                                <div
                                    className="howManyTimes_button"
                                    onClick={handleCountUpTime}
                                >
                                    <IonIcon icon={addOutline}/>
                                </div>
                            </div>


                        </div>

                    </div>
                    <FooterForm text={"Suivant"} onClick={handleSubmit}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Question1;