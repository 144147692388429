import React, {useState} from 'react';
import {IonContent, IonHeader, IonText, IonToolbar, IonPage, IonImg, IonButton, IonAlert} from "@ionic/react";
import "./analysesIndex.css"


const AnalysesIndex = () => {
    return (

            <div className="analyse">
                <div className="analyse_title_content">


                </div>

                <div className="analyse_content">
                    <IonText><h2 className="analyse_text">Bientôt disponible !</h2></IonText>

                </div>



        </div>
    );
};

export default AnalysesIndex;