import React, {createContext, useContext, useEffect, useState} from 'react';
import Pedagogie from "./content/Pedagogie";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios"
import PedagogieDetails from "./content/content/PedagogieDetails";
import {clearPedagogie, getPedagogie} from "../../../redux/slices/pedagogie/pedagogie.slice";

const PedagogieIndex = () => {
    const user = useSelector(state => state.currentUser)
    const [pedagogie, setPedagogie] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_STAGING_V2}/pedagogie/forEach`, {

                debug: false,
                filter: [],
                fields: [],
            },
            {
                headers: {
                    Authorization: user.token,
                },
            }
        )
            .then((res) => {
                setPedagogie(res.data.Data)
                dispatch(getPedagogie(res.data))

            })
            .catch((err) => console.error(err));
    }, [])

    return (
        <div>

            <Pedagogie data={pedagogie}/>

        </div>
    );
};

export default PedagogieIndex;
