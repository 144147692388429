import { createSlice } from '@reduxjs/toolkit';

export const newPlanSlice = createSlice({
  name: 'newPlan',
  initialState: [
    {
      question1: null,
      question2: null,

    }],
  reducers: {
    changeNewPlan: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { changeNewPlan } = newPlanSlice.actions;
