import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFab,
    IonFabButton,
    IonIcon,
    IonModal
  } from "@ionic/react";
import Profil from "../components/App/Profil/Profil";
import { chatbubbleEllipsesOutline,} from "ionicons/icons";
import { useState } from "react";

  
  import "./Tab4.css";
import Contact from "../components/App/Profil/Contact/Contact";
  
  const Tab4 = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <IonPage>
        <IonContent className="app_content" >
          <Profil/>
        </IonContent>
      </IonPage>
    );
  };
  
  export default Tab4;
  