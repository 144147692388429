import React, {useEffect, useState} from "react";
import {IonRouterLink, IonSkeletonText, IonText} from "@ionic/react";


const SkeletonPedaList = () => {

    const [showMessage, setShowMessage] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            setShowMessage(true)
        }, 5000)

    }, [showMessage])


    return (
        <>
            {
                showMessage ?
                    <h3 className="pedagogie_title" style={{textAlign: "center", margin: "40px"}}>Oups ! une erreur est
                        survenue réessaie plus tard !</h3> :


                    <div className="pedagogie_seance">
                        <h4 className="pedagogie_seance_title">Séances</h4>


                        <ul className="pedagogie_seance_list">

                            <li className="pedagogie_seance_list_item">
                                <IonSkeletonText
                                    animated

                                ></IonSkeletonText>
                            </li>
                            <li className="pedagogie_seance_list_item">
                                <IonSkeletonText
                                    animated

                                ></IonSkeletonText>
                            </li>
                            <li className="pedagogie_seance_list_item">
                                <IonSkeletonText
                                    animated

                                ></IonSkeletonText>
                            </li>
                            <li className="pedagogie_seance_list_item">
                                <IonSkeletonText
                                ></IonSkeletonText>
                            </li>


                        </ul>
                    </div>
            }

        </>
    );
};

export default SkeletonPedaList;