import {
    IonContent,
    IonPage,
    IonHeader,
    IonText,
    IonInput,
    IonItem,
    IonButton,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonImg,
    IonIcon, IonAlert,
} from "@ionic/react";

import {useState} from "react";
import {eyeOffOutline, eyeOutline} from "ionicons/icons";
import {ChangeLogin, currentUser} from "../../../../../redux/slices/currentUser/currentUserSlice";
import axios from "axios"
import {useSelector} from "react-redux";
import './updatePassword.css'
import {Capacitor} from "@capacitor/core";


const UpdatePassword = ({setUpdatePassword}) => {
    const user = useSelector(state => state.currentUser)
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState(null);
    const [showMessage, setShowMessage] = useState(null)
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const [showAlert, setShowAlert] = useState(false)

    const handleSubmit = () => {

        axios.post(`${process.env.REACT_APP_STAGING_V2}/user/changePassword`, {
            debug: false,
            data: {
                email: user.email,
                oldPassword: oldPassword,
                newPassword: password
            },
            args: {}
        }, {

            headers: {
                authorization: user.token,
            },

        }).then(res => {
            if (res.status === 200) {
                alert("Ton mot de passe à bien était modifié !")
                setUpdatePassword(false)

            }
            if (res.data.error) {
                setShowMessage(res.data.error)
            } else {
                setShowMessage(res.data.message)
            }
        }).catch(err => console.log(err))
        setShowAlert(false)
    }


    return (
        <div className={"updatePassword"}>
            <div className="email_error" dangerouslySetInnerHTML={{__html: showMessage}}></div>

            <form className="updatePassword_form" onSubmit={(e) => {
                e.preventDefault();
                setShowAlert(true)
            }}>
                <div className="updatePassword_content">
                    <input
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Ancien mot de passe"
                        type={showOldPassword ? "text" : "password"}
                        name="oldPassword"
                        className="updatePassword_input"
                        autoComplete='off'
                    />

                    {showOldPassword ? (
                        <IonIcon
                            onClick={() => setShowOldPassword(false)}
                            icon={eyeOutline}
                            className="updatePassword_input_icon"
                        ></IonIcon>
                    ) : (
                        <IonIcon
                            onClick={() => setShowOldPassword(true)}
                            icon={eyeOffOutline}
                            className="updatePassword_input_icon"
                        ></IonIcon>
                    )}
                </div>
                <div className="updatePassword_content">
                    <input minLength={5}
                           onChange={(e) => setPassword(e.target.value)}
                           placeholder="Nouveau mot de passe"
                           type={showPassword ? "text" : "password"}
                           name="password"
                           className="updatePassword_input"
                           autoComplete='off'
                    />

                    {showPassword ? (
                        <IonIcon
                            onClick={() => setShowPassword(false)}
                            icon={eyeOutline}
                            className="updatePassword_input_icon"
                        ></IonIcon>
                    ) : (
                        <IonIcon
                            onClick={() => setShowPassword(true)}
                            icon={eyeOffOutline}
                            className="updatePassword_input_icon"
                        ></IonIcon>
                    )}
                </div>
                {
                    password.length >= 1 && password.length < 5 ?
                        <IonText><p className="register_invalidPassword">Votre mot de passe est trop court ( 5
                            charactères minimum )</p></IonText> : ""
                }

                <input disabled={password.length < 5 ? "disabled" : ""} className="updatePassword_button" type="submit"
                       value="Modifier"/>
            </form>

            <IonAlert
                mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                isOpen={showAlert}
                onDidDismiss={() => {
                    setShowAlert(false);
                }}
                header={String.fromCodePoint("9888") + "" + " Attention " + "" + String.fromCodePoint("9888")}
                message="Tu es sur le point de changer de mot de passe !"
                buttons={[
                    {
                        text: "Annuler",
                        role: "cancel",
                        handler: () => {
                            setShowAlert(false)
                        }
                    },
                    {
                        text: "Modifier",
                        role: "confirm",
                        handler: handleSubmit
                    },
                ]}
            />
        </div>
    )
}

export default UpdatePassword;
