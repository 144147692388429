import React,{useEffect} from 'react';
import {IonIcon, IonSkeletonText} from "@ionic/react";
import {chevronDown, chevronForward, exitOutline} from "ionicons/icons";
import logoProfil from "../../../../assets/pictoFlit/PROTOTYPE - Design pour Loïc (1).png";
import ObjMain from "../../../../assets/profil/4.png";
import moment from "moment/moment";
import ObjInter from "../../../../assets/profil/5.png";
import Nbseance from "../../../../assets/profil/6.png";
import {isEmpty} from "../../../../Utils/Utils";
import ProfilModal from "../modal/ProfilModal";
import connect from "../../../../assets/profil/7.png";
import settings from "../../../../assets/profil/8.png";
import {useDispatch, useSelector} from "react-redux";
import {emptyGoals} from "../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../API/Goal/GetGoal";
import {IsVipOrNot} from "../../../../API/User/VipOrNot";


const SkeletonProfil = ({handleDeconnect}) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser)

    useEffect(() => {

        const interval = setInterval(() => {
            dispatch(emptyGoals());
            dispatch(forEachGoal(currentUser))


        }, 1000);
        return () => clearInterval(interval);
    }, [currentUser]);
    return (
        <>
            <div className={"profil_title_icon"}>
                <div></div>
                <IonIcon onClick={handleDeconnect} className={"profil_header_icon"} icon={exitOutline}/>


            </div>
            <div className={"profil_header_logo_name"}>
                <ul style={{width:"100%",height:"200px",display:"flex",justifyContent:"space-between",flexDirection:"column",marginTop:"60px"}}>
                    <li><IonSkeletonText style={{
                        width: "80%",
                        margin: "auto",
                        height: "30px",
                    }} animated/></li>
                    <li><IonSkeletonText style={{
                        width: "80%",
                        margin: "auto",
                        height: "30px",
                    }}
                        animated/></li>
                    <li><IonSkeletonText style={{
                        width: "80%",
                        margin: "auto",
                        height: "30px",
                    }} animated/></li>
                </ul>

            </div>

            <div className={"profil_content"}>

                <ul className={"profil_content_list"}>
                    <li className={"profil_content_list_item"}>
                        <div className={"profil_content_box"}>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                            <div className="profil_content_text_box">
                                <IonSkeletonText animated className={'profil_content_text'}></IonSkeletonText>
                                <ul className={"profil_content_list_objectif"}>

                                    <li><IonSkeletonText animated
                                                         className={'profil_content_text_goal'}></IonSkeletonText>
                                    </li>


                                </ul>
                            </div>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                        </div>
                    </li>

                    <li className={"profil_content_list_item"}>
                        <div className={"profil_content_box"}>
                            <IonSkeletonText animated
                                             className={"profil_content_icon"}/>
                            <div className="profil_content_text_box">
                                <IonSkeletonText animated className={'profil_content_text'}></IonSkeletonText>
                                <ul className={"profil_content_list_objectif"}>

                                    <li><IonSkeletonText animated
                                                         className={'profil_content_text_goal'}></IonSkeletonText>
                                    </li>


                                </ul>
                            </div>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                        </div>
                    </li>

                    <li className={"profil_content_list_item"}>
                        <div className={"profil_content_box"}>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                            <div className="profil_content_text_box">
                                <IonSkeletonText animated className={'profil_content_text'}></IonSkeletonText>
                                <IonSkeletonText animated className={'profil_content_text_goal'}></IonSkeletonText>
                            </div>
                            <IonSkeletonText animated
                                             className={"profil_content_icon"}/>
                        </div>

                    </li>
                    <li className={"profil_content_list_item"}>
                        <div className={"profil_content_box"}>
                            <IonSkeletonText animated
                                             className={"profil_content_icon"}/>
                            <div className="profil_content_text_box">
                                <IonSkeletonText animated className={'profil_content_text'}></IonSkeletonText>
                                <IonSkeletonText animated className={'profil_content_text_goal'}></IonSkeletonText>
                            </div>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                        </div>
                    </li>
                    <li className={"profil_content_list_item"}>
                        <div className={"profil_content_box"}>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                            <div className="profil_content_text_box">
                                <IonSkeletonText animated className={'profil_content_text'}></IonSkeletonText>
                            </div>
                            <IonSkeletonText animated className={"profil_content_icon"}/>
                        </div>
                    </li>
                </ul>

            </div>
        </>

    );
};

export default SkeletonProfil;