import { createSlice } from '@reduxjs/toolkit';

export const garminSlice = createSlice({
  name: 'garmin',
  initialState: [],
  reducers: {
    getGarmin: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { getGarmin } = garminSlice.actions;
