import {createSlice} from '@reduxjs/toolkit';


export const pedagogieSlice = createSlice({
    name: 'pedagogie',
    initialState: [],
    reducers: {
        getPedagogie: (state, action) => {
            return {...state, ...action}
        },

        clearPedagogie: (state, action) => {
            return []
        },

    },
});

export const fromPedagogieSlice = createSlice({
    name: 'fromPedagogie',
    initialState: {
        fromDetails: false,
        fromConseils: false
    },
    reducers: {
        getFromPedagogie: (state, action) => {
            return {...state, ...action}
        },


    },
});

export const {getPedagogie, clearPedagogie} = pedagogieSlice.actions
export const {getFromPedagogie} = pedagogieSlice.actions
