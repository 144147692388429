import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IonContent, IonIcon, IonPage,} from "@ionic/react";
import {useHistory, useParams} from "react-router";
import {getPedagogie} from "../../../../../redux/slices/pedagogie/pedagogie.slice";
import {chevronBack} from "ionicons/icons";
import "./pedagogieDetails.css"
import {isEmpty} from "../../../../../Utils/Utils";
import axios from "axios";
import trente from "../../../../../assets/iconSeance/3030.png";
import seuil from "../../../../../assets/iconSeance/seuil.png";
import entrainementNP from "../../../../../assets/iconSeance/entrainement_non_planifie.png";
import enduranceFondamental from "../../../../../assets/iconSeance/endurance_fondamentale.png";
import cote from "../../../../../assets/iconSeance/cote.png";
import VMAIcon from "../../../../../assets/iconSeance/VMA.png";
import tempo from "../../../../../assets/iconSeance/tempo.png";
import sortieLongue from "../../../../../assets/iconSeance/sortie_longue.png";
import fractionneBI from "../../../../../assets/iconSeance/fractionne_basse_intensit‚.png";
import fractionneLong from "../../../../../assets/iconSeance/fractionne_long.png";
import allureSoutenue from "../../../../../assets/iconSeance/footing_allure_soutenue.png";
import progressif from "../../../../../assets/iconSeance/progressif.png";
import fractionneCourt from "../../../../../assets/iconSeance/fractionne_court.png";
import ligneDroite from "../../../../../assets/iconSeance/lignes droites.png";
import chaussure from "../../../../../assets/menu/semaine.png";
import monte from "../../../../../assets/iconSeance/montee en charge.png";
import recup from "../../../../../assets/iconSeance/recuperation.png";

const PedagogieDetails = () => {
    const {libelle} = useParams()
    const user = useSelector(state => state.currentUser)
    const peda = useSelector(state => state.pedagogie)
    const dispatch = useDispatch()
    const history = useHistory()
    const iconSeance = (data) => {

        if (data === "30/30") {
            return trente
        }
        if (data === "Seuil") {
            return seuil
        }
        if (data === "Entraînement non planifié" || data === "Non planifié") {
            return entrainementNP
        }
        if (data === "Endurance fondamentale") {
            return enduranceFondamental
        }
        if (data === "Côtes") {
            return cote
        }
        if (data === "VMA") {
            return VMAIcon
        }
        if (data === "Tempo") {
            return tempo
        }
        if (data === "Sortie longue") {
            return sortieLongue
        }
        if (data === "Basse intensité") {
            return fractionneBI
        }
        if (data === "Fractionné long") {
            return fractionneLong
        }
        if (data === "Allure soutenue") {
            return allureSoutenue
        }
        if (data === "Progressif") {
            return progressif
        }
        if (data === "Fractionné court") {
            return fractionneCourt
        }
        if (data === "Lignes droites") {
            return ligneDroite
        }
        if (data === "Montée en charge") {
            return monte
        }
        if (data === "Récupération") {
            return recup
        } else {
            return chaussure
        }
    }

    useEffect(() => {


        axios.post(`${process.env.REACT_APP_STAGING_V2}/pedagogie/forEach`, {

                debug: false,
                filter: [],
                fields: [],
            },
            {
                headers: {
                    Authorization: user.token,
                },
            }
        )
            .then((res) => {
                dispatch(getPedagogie(res.data))

            })
            .catch((err) => console.error(err));


    }, [user.token, dispatch])


    return (
        <IonPage>

            <IonContent className="app_content">


                {
                    !isEmpty(peda) && (
                        <>

                            {
                                libelle === "30-30" && (<> {peda.payload.Data.filter(name => name.info.title.trim() === "30/30").map(res => {
                                    return (
                                        <div className="pedaDetails">
                                            <div
                                                className={res.idTag === "sde" ? "pedaDetails_title_content_phase" : "pedaDetails_title_content_type"}>
                                                <div className={"pedaDetails_backIcon_content"}>
                                                    <IonIcon onClick={() => history.goBack()} className="pedaDetails_backIcon"
                                                             icon={chevronBack}/>
                                                </div>
                                                {res.idTag === "sde" && (
                                                    <>
                                                        <h3 className="pedaDetails_title_content_title">Phases
                                                            d'entraînement</h3>
                                                    </>
                                                )}
                                                {res.idTag === "aaa" && (
                                                    <>
                                                        <h3 className="pedaDetails_title_content_title">Types
                                                            d'entraînement</h3>
                                                    </>
                                                )}
                                                <img
                                                    className={res.idTag === "sde" ? "pedaDetails_icon_phase" : "pedaDetails_icon_type"}
                                                    src={iconSeance(res.info.title)} alt={res.info.title}/>
                                                <h2 style={{
                                                    color: `${res.idTag === "sde" ? "var(--lightGreenFlit)" : "var(--greenFlit)"}`,
                                                    textAlign: "center",
                                                }}>{res.info.title}</h2>
                                            </div>


                                            <div className="pedaDetails_info_content">

                                                <h5 className="pedaDetails_info_content_title">{String.fromCodePoint(parseInt(res.info.subtitle_emoji))} {res.info.subtitle} {String.fromCodePoint(parseInt(res.info.subtitle_emoji))}</h5>
                                                <ul className="pedaDetails_info_content_list">

                                                    {
                                                        res.info.content.map(r => (
                                                            <li className="pedaDetails_info_content_list_item">
                                                                < p className="pedaDetails_info_content_title_text">{r}</p>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>

                                                <div className="pedaDetails_info_content_list_box">
                                                    <h5 className="pedaDetails_info_content_title">💡 Des conseils
                                                        pour réussir 💡</h5>
                                                    <ul className="pedaDetails_info_content_list">

                                                        {
                                                            res.info.tips.map(m => (
                                                                <li className="pedaDetails_info_content_list_item">
                                                                    <p> &#x2022; {m}</p>
                                                                </li>
                                                            ))
                                                        }


                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })} </>)}
                            {
                                libelle === "Marche-Course" && (<> {peda.payload.Data.filter(name => name.info.title.trim() === "Marche/Course").map(res => {
                                    return (
                                        <div className="pedaDetails">
                                            <div
                                                className={res.idTag === "sde" ? "pedaDetails_title_content_phase" : "pedaDetails_title_content_type"}>
                                                <div className={"pedaDetails_backIcon_content"}>
                                                    <IonIcon onClick={() => history.goBack()} className="pedaDetails_backIcon"
                                                             icon={chevronBack}/>
                                                </div>
                                                {res.idTag === "sde" && (
                                                    <>
                                                        <h3 className="pedaDetails_title_content_title">Phases
                                                            d'entraînement</h3>
                                                    </>
                                                )}
                                                {res.idTag === "aaa" && (
                                                    <>
                                                        <h3 className="pedaDetails_title_content_title">Types
                                                            d'entraînement</h3>
                                                    </>
                                                )}
                                                <img
                                                    className={res.idTag === "sde" ? "pedaDetails_icon_phase" : "pedaDetails_icon_type"}
                                                    src={iconSeance(res.info.title)} alt={res.info.title}/>
                                                <h2 style={{
                                                    color: `${res.idTag === "sde" ? "var(--lightGreenFlit)" : "var(--greenFlit)"}`,
                                                    textAlign: "center",
                                                }}>{res.info.title}</h2>
                                            </div>


                                            <div className="pedaDetails_info_content">

                                                <h5 className="pedaDetails_info_content_title">{String.fromCodePoint(parseInt(res.info.subtitle_emoji))} {res.info.subtitle} {String.fromCodePoint(parseInt(res.info.subtitle_emoji))}</h5>
                                                <ul className="pedaDetails_info_content_list">

                                                    {
                                                        res.info.content.map(r => (
                                                            <li className="pedaDetails_info_content_list_item">
                                                                < p className="pedaDetails_info_content_title_text">{r}</p>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>

                                                <div className="pedaDetails_info_content_list_box">
                                                    <h5 className="pedaDetails_info_content_title">💡 Des conseils
                                                        pour réussir 💡</h5>
                                                    <ul className="pedaDetails_info_content_list">

                                                        {
                                                            res.info.tips.map(m => (
                                                                <li className="pedaDetails_info_content_list_item">
                                                                    <p> &#x2022; {m}</p>
                                                                </li>
                                                            ))
                                                        }


                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })} </>)}
                            {
                                libelle !== "30-30" && libelle !== "Marche-Course" && (

                                    <>
                                        {
                                            isEmpty(peda.payload.Data.filter(name => name.info.title.trim() === libelle.trim()) ) && (
                                                <div style={{height: "100%", display: "flex", padding: "0px 10px 0px 10px"}}>
                                                    <h5 style={{textAlign: "center", margin: "auto"}}>Oups une erreur est survenu,
                                                        les tips pour ce type de séances ne sont pas encore
                                                        disponible</h5>
                                                </div>)
                                        }
                                        <>
                                            {peda.payload.Data.filter(name => name.info.title.trim() === libelle.trim()).map(res => {
                                                return (
                                                    <div className="pedaDetails">
                                                        <div
                                                            className={res.idTag === "sde" ? "pedaDetails_title_content_phase" : "pedaDetails_title_content_type"}>
                                                            <div className={"pedaDetails_backIcon_content"}>
                                                                <IonIcon onClick={() => history.goBack()}
                                                                         className="pedaDetails_backIcon" icon={chevronBack}/>
                                                            </div>
                                                            {res.idTag === "sde" && (
                                                                <>
                                                                    <h3 className="pedaDetails_title_content_title">Phases
                                                                        d'entraînement</h3>
                                                                </>
                                                            )}
                                                            {res.idTag === "aaa" && (
                                                                <>
                                                                    <h3 className="pedaDetails_title_content_title">Types
                                                                        d'entraînement</h3>
                                                                </>
                                                            )}
                                                            <img
                                                                className={res.idTag === "sde" ? "pedaDetails_icon_phase" : "pedaDetails_icon_type"}
                                                                src={iconSeance(res.info.title)} alt={res.info.title}/>
                                                            <h2 style={{
                                                                color: `${res.idTag === "sde" ? "var(--lightGreenFlit)" : "var(--greenFlit)"}`,
                                                                textAlign: "center",
                                                            }}>{res.info.title}</h2>
                                                        </div>

                                                        <div className="pedaDetails_info_content">

                                                            <h5 className="pedaDetails_info_content_title">{String.fromCodePoint(parseInt(res.info.subtitle_emoji))} {res.info.subtitle} {String.fromCodePoint(parseInt(res.info.subtitle_emoji))}</h5>
                                                            <ul className="pedaDetails_info_content_list">

                                                                {
                                                                    res.info.content.map(r => (
                                                                        <li className="pedaDetails_info_content_list_item">
                                                                            < p className="pedaDetails_info_content_title_text">{r}</p>
                                                                        </li>
                                                                    ))
                                                                }

                                                            </ul>

                                                            <div className="pedaDetails_info_content_list_box">
                                                                <h5 className="pedaDetails_info_content_title">💡 Des conseils
                                                                    pour réussir 💡</h5>
                                                                <ul className="pedaDetails_info_content_list">

                                                                    {
                                                                        res.info.tips.map(m => (
                                                                            <li className="pedaDetails_info_content_list_item">
                                                                                <p> &#x2022; {m}</p>
                                                                            </li>
                                                                        ))
                                                                    }


                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    </>
                                )

                            }


                        </>
                    )
                }


            </IonContent>

        </IonPage>
    );
};

export default PedagogieDetails;
