import {
    IonContent,
    IonHeader,
    IonPage,

    IonToolbar,
} from "@ionic/react";
import Settings from "../components/App/Profil/Settings/Setting";
import AnalysesIndex from "../components/App/Analyses/AnalysesIndex";

const Tab2 = () => {
    return (
        <IonPage>
            <IonHeader class="ion-no-border" mode="ios">
                <IonToolbar>
                </IonToolbar>
            </IonHeader>
            <IonContent className="app_content" class="scroll-content">
                <AnalysesIndex/>
            </IonContent>
        </IonPage>
    );
};

export default Tab2;
