import React, { useState, useEffect } from "react";
import {
  IonBackButton,
  IonHeader,
  IonText,
  IonToolbar,
  IonPage,
  IonButtons,
  IonContent,
} from "@ionic/react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeNewPlan } from "../../../../redux/slices/newPlan/newPlanSlice";
import { useHistory } from "react-router-dom";

const WeekBilanVolume = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // ? Ancienne version
  const [dataBilan, setDataBilan] = useState();
  // ? Nouvelle version
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState(false);
  const user = useSelector((state) => state.currentUser);

  useEffect(() => {
    // ? Ancienne version
    // axios
    //   .get(`${process.env.REACT_APP_STAGING_V3}/questions/findAll`)
    //   .then((res) => setDataBilan(res.data))
    //   .catch((err) => console.log(err));

    // ? Nouvelle version
    setIsLoading(true);
    setTimeout(() => {
      const LoadData = async () => {
        // ? Récupération des questions
        await axios
          .post(
            `${process.env.REACT_APP_STAGING_V2}/questions/forEach`,
            {
              debug: false,
              filter: [],
              fields: [],
            },
            {
              headers: {
                authorization: user.token,
              },
            }
          )
          .then((res) => {
            // setDataBilan(res.data)
            setQuestions(res.data);
          })
          .catch((err) => console.log(err));
        // ? Récupération des réponses
        await axios
          .post(
            `${process.env.REACT_APP_STAGING_V2}/answers/forEach`,
            {
              debug: false,
              filter: [],
              fields: [],
            },
            {
              headers: {
                authorization: user.token,
              },
            }
          )
          .then((res) => {
            // ? Récupérer les 5 premières questions
            // ? Car sur la V2 ce n'est plus le bon formatage de ma version V1'
            const size = 10;
            const items = res.data.Data.slice(5, size);
            setAnswers(items);
          })
          .catch((error) => console.log(error));
      };
      LoadData();
    }, 1000);
    setIsLoading(false);
  }, []);

  const handleChangeSlide = (title) => {
    dispatch(changeNewPlan({ question2: title }));
    history.push("/weekbilandisponibility");
  };
  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar mode="ios" className="app_toolbar">
          <IonButtons>
            <IonBackButton
              text=""
              defaultHref="/weekbilanallure"
              className="app_backButtons"
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isLoading ? (
          ""
        ) : (
          <div className="weekBilan">
            <div className="weekBilan_title">
              <IonText className="weekBilan_content_list_text_title">
                <h2> ✅ Bilan de la semaine</h2>
              </IonText>
              <IonText>
                <p className="weekBilan_title_subtitle">
                  {/* {dataBilan.data[1].question} */}
                  {questions ? <>{questions.Data[1].libelle}</> : <></>}
                </p>
              </IonText>
            </div>
            <div className="weekBilan_content">
              <ul className="weekBilan_content_list">

                {answers ? (
                  <>
                    {answers.map((answer, index) => (
                      <li
                        key={index}
                        className="weekBilan_content_list_item "
                        id="toFast"
                        onClick={() => handleChangeSlide(answer.title)}
                      >
                        <div className="weekBilan_content_list_icon">
                          <span className="weekBilan_content_list_text_icon_one">
                            {/* Je n'arrive pas à afficher les emojis */}
                            {String.fromCodePoint(parseInt(answer.icon))}
                          </span>
                        </div>
                        <div className="weekBilan_content_list_text">
                          <IonText >
                            <h4 className="weekBilan_content_title_box">{answer.title}</h4>
                          </IonText>
                          <IonText>
                            <p className="weekBilan_content_text_box">
                              {answer.description}
                            </p>
                          </IonText>
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WeekBilanVolume;
