import {

    IonContent,

    IonItem,

    IonText,

    IonInput,
    IonTextarea,
    IonButton,
    IonIcon,
} from "@ionic/react";
import React, {useRef, useState} from "react";
import "./contact.css";
import {paperPlaneOutline} from "ionicons/icons";
import emailjs from "@emailjs/browser"
import {EmailComposer} from "@awesome-cordova-plugins/email-composer"
import {isPlatform} from "@ionic/react";

const Contact = (props) => {
    const setIsOpen = props.setIsOpen;
    const user = "Allard"
    const form = useRef()


    const [support, setSupport] = useState({
        email: null,
        message: null
    })

    const handleSupport = (e) => {
        setSupport({...support, [e.target.name]: e.target.value})
    };

    // "flitsport.dev@gmail.com"

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4cdjeob', 'template_292zyte', form.current, 'eAD0_QGQTBsMdpRmn')
            .then((result) => {

            }, (error) => {

            });
    };

    let options = {
        to: "labaisse.loic@gmail.com",
        cc: support.email,
        subject: "Support",
        body: [support.message, `send by Mr ${user}`],
        isHtml: true
    }

    const handleSubmit = () => {
        EmailComposer.open(options)
        setIsOpen(false)
    }

    return (
        <IonContent>


            {isPlatform("desktop") ? <div className="contact_desktop">
                <form className="contact_desktop_form" ref={form} onSubmit={sendEmail}>
                    <label className="contact_desktop_label">Nom</label>
                    <input className="contact_desktop_input" type="text" name="user_name"/>
                    <br/>
                    <label className="contact_desktop_label">Email</label>
                    <input className="contact_desktop_input" type="email" name="user_email"/>
                    <br/>
                    <label className="contact_desktop_label">Message</label>
                    <textarea rows="10"  className="contact_desktop_area" name="message"/>
                    <br/>
                    <div className="contact_desktop_button_div">
                    <input className="contact_desktop_button" type="submit" value="Send"/>
                    </div>
                </form>
            </div> : <div className="contact">
                <div className="contact_title">
                    <IonText>
                        <h1 className="contact_title_flit">Flit</h1>
                    </IonText>
                    <IonText>
                        <h1 className="contact_title_support">Support</h1>
                    </IonText>
                </div>

                <div className="contact_content">
                    <IonItem>
                        <IonInput
                            placeholder="E-mail"
                            type="email"
                            name="email"
                            onIonChange={handleSupport}
                        />
                    </IonItem>
                    <IonItem>
                        <IonTextarea
                            placeholder="Ecrivez votre message"
                            type="text"
                            name="message"
                            onIonChange={handleSupport}
                            autoCorrect="on"
                        />
                    </IonItem>


                </div>
                <div className="contact_button_content">
                    <IonButton
                        className="contact_button"
                        onClick={handleSubmit}
                    >
                        <IonIcon
                            className="contact_button_icon"
                            icon={paperPlaneOutline}
                        />
                    </IonButton>
                </div>
            </div>
            }
        </IonContent>
    );
};

export default Contact;
