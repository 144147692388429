import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonButton,

  IonText,

  IonDatetime,
  IonModal, IonIcon, IonAlert, isPlatform,
} from "@ionic/react";
  import React, {useEffect, useState} from "react";
  import { useSelector } from "react-redux";
  import { useHistory } from "react-router";
  import * as IoIcons from "react-icons/io5";
  import * as moment from "moment";
  import "moment/locale/fr";
  import axios from "axios";
  import './newGoal.css'
import SkeletonObjectif from "../../../Formulaire/FormContent/Objectif/SkeletonObjectif/SkeletonObjectif";
import {chevronDown} from "ionicons/icons";
import FooterForm from "../../../Reusable/FooterForm";
import {Capacitor} from "@capacitor/core";
  
  const NewGoal = () => {
    const [question, setQuestions] = useState();
    const [showModal,setShowModal] = useState(false)
    const [goalId,setGoalId] = useState()
    const user = useSelector((state) => state.currentUser);
    const history = useHistory();
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));


    const [showAlert, setShowAlert] = useState(false);

    const [newGoal, setnewGoal] = useState({
      name: "",
      id: "",
    });


    useEffect(() => {

      axios
          .post(
              `${process.env.REACT_APP_STAGING_V2}/goalType/find`,
              {
                debug: false,
                filter: [],
                fields: [],
              },
              {
                headers: {
                  Authorization: user.token,
                },
              }
          )
          .then((res) => {
            setQuestions(res.data);
            axios
                .post(
                    `${process.env.REACT_APP_STAGING_V2}/goals/foreach`,
                    {
                      debug: false,
                      filter: [{field : "userId" , comparator : "==", operand :[[user.id]]}],
                      fields: [],
                    },
                    {
                      headers: {
                        Authorization: user.token,
                      },
                    }
                )
                .then((res) => {
                  setGoalId(res.data.Data[0].id);
                })
                .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));



    }, [user.token]);

    const handlenewGoal = (e) => {
      const {name, value} = e.target;
      setnewGoal({...newGoal, name: name, id: value});

    };
    const handleChooseDate = (e) => {
      const {value} = e.target;

      if (newGoal.name === "Progresser sans objectif") {
        setDate(null);
      } else {
        const dataDate = value;
        setDate(dataDate);


      }
    };

    const handleSubmit = () => {

      if( date === undefined){
        setShowAlert(true)
      }else {
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/goals/update`,
                {
                  debug: false,
                  data: {
                    userId: user.id,
                    main: true,
                    goalType: newGoal.id,
                    id:goalId,
                    date: date.concat(" 00:00:00 +0000 UTC"),
                  },
                },
                {
                  headers: {
                    Authorization: user.token,
                  },
                }
            )
            .then((res) => {
              if (res.status === 200) {
                history.push("/newGoalDisponibility");
              }
              if (res.status === 400) {
                setShowAlert(true);
              }
            })
            .catch((err) => console.error(err));
      }
    };

    return (
        <IonPage>
          <IonHeader class="ion-no-border">
            <IonToolbar mode="ios">
              <IonButtons slot="start">
                <IonBackButton
                    text=""
                    defaultHref="/tab4"
                    className="headerBackButton"
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {question === undefined ? (
                <SkeletonObjectif/>
            ) : (
                <div className="newGoal_objectif">
                <div className="newGoal div_principal">

                  <IonText className="typeOfRun_title"><h2>🎯 Ton objectif</h2></IonText>

                  <ul className="newGoal_list">
                    {question.Data.map((q) => (
                        <li key={q.id} className="newGoal_list_item">
                          <button
                              onClick={handlenewGoal}
                              value={q.id}
                              name={q.name}
                              className={
                                newGoal.name === q.name
                                    ? "newGoal_list_item_button type_active"
                                    : "newGoal_list_item_button"
                              }
                          >
                            {q.name}
                          </button>
                        </li>
                    ))}
                  </ul>

                  <div className="newGoal_time">
                    <ul className="newGoal_time_list">
                      <li className="newGoal_time_list_item">
                        <IonButton
                            id="open-modal"
                            onClick={()=> setShowModal(true)}
                            className="newGoal_modal_button"
                            disabled={newGoal.name === "Progresser sans objectif"}
                        >
                          {date === null ? (
                              <div className="newGoal_time_date_text">
                                <IoIcons.IoCalendarNumber/>
                                <p>Date</p>
                                <IonIcon icon={chevronDown}/>
                              </div>
                          ) : (
                              <>{
                                moment(date).format("ll", "fr-FR")} <IonIcon icon={chevronDown}/>
                              </>
                          )}
                        </IonButton>
                        <IonModal mode={isPlatform("ios") ? "ios" :"md"} className="newGoal_modal" isOpen={showModal} onDidDismiss={()=> setShowModal(false)}>
                          <IonDatetime
                              max="2061-12-12"
                              min={moment().format("YYYY-MM-DD")}
                              value={date}
                              presentation="date"
                              name="date"
                              onIonChange={handleChooseDate}
                          ></IonDatetime>
                        </IonModal>
                      </li>
                    </ul>
                  </div>
                  <IonAlert
                      mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                      isOpen={showAlert}
                      onDidDismiss={() => {
                        setShowAlert(false);
                      }}
                      header="Oops !"
                      subHeader="Une erreur est survenue"
                      message="Tu dois saisir tous les champs"
                      buttons={["OK"]}
                  />

                  <FooterForm onClick={handleSubmit}/>
                </div>
                </div>
            )}

          </IonContent>
        </IonPage>
    );
  };
  
  export default NewGoal;
  