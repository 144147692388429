import React from 'react';
import {IonButton, IonSpinner} from "@ionic/react";
import "./footer_form.css"


const FooterForm = ({onClick,text,spin}) => {

    return (
        <div className="footer">

            <IonButton onClick={onClick} className="footer_button">{spin ? <IonSpinner name={"crescent"}/> :text}</IonButton>

        </div>
    );
};

export default FooterForm;