import axios from "axios";
import {getGoal} from "../../redux/slices/goals/goalSlice";

export const forEachGoal = (data) => async (dispatch) => {

    // Api Calls -- for get the user goals
    // data  = currentUser in redux
    await axios
        .post(
            `${process.env.REACT_APP_STAGING_V2}/goals/foreach`,
            {
                debug: false,
                filter: [
                    {
                        field: "userId",
                        comparator: "==",
                        operand: [[data.id]],
                    },
                ],
                fields: [],
            },
            {
                headers: {
                    Authorization: data.token,
                },
            }
        ).then((res => {

                // if back return 200 we going to loop on Data
                if(res.status === 200){
                    dispatch(getGoal(res.data.Data))

                }
            }
        )).catch(e=> console.log(e))
}