import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../../../../Utils/Utils";
import {IonIcon} from "@ionic/react";
import {closeOutline} from "ionicons/icons";
import moment from "moment";
import * as AiIcons from "react-icons/ai";
import {emptyGoals, getGoal} from "../../../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../../../API/Goal/GetGoal";
import {useHistory} from "react-router";

const ModifyInter = () => {
    const [getInter, setGetInter] = useState([]);
    const currentUser = useSelector(state => state.currentUser)
    const dispatch = useDispatch();
    const history= useHistory()

    useEffect(() => {
        // get all goals for current user
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/goals/foreach`,
                {
                    debug: false,
                    filter: [
                        {
                            field: "userId",
                            comparator: "==",
                            operand: [[currentUser.id]],
                        },
                    ],
                    fields: [],
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            ).then(async res => {
            if (res.status === 200) {


                //  when we get all goals, filter all not main goals et push it to state
                for (let i = 0; i < res.data.Data.length; i++) {

                    if (res.data.Data[i].main === false) {
                        await axios.post(`${process.env.REACT_APP_STAGING_V2}/goalType/find`, {
                            debug: false,
                            data: {
                                id: res.data.Data[i].goalType,
                            }
                        }, {
                            headers: {
                                Authorization: currentUser.token
                            }
                        })

                            .then((data) => {

                                setGetInter(current => [...current, {
                                    id: Math.floor(Math.random() * 1000),
                                    name: data.data.Data[0].name,
                                    main: false,
                                    date: res.data.Data[i].date,
                                    goalId: res.data.Data[i].id
                                }])
                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    }

                }


            }
        }).catch(e => console.log(e))

    }, [])



    // function for delete goal
    // We get id who created manually for the front
    // and the goalId for the back
    const handleDelete = (id, goalId) => {

        axios.post(`${process.env.REACT_APP_STAGING_V2}/goals/delete`, {
                debug: false,
                data: {
                    id: goalId,
                },
                args: {}
            }, {
                headers: {
                    Authorization: currentUser.token,
                },
            }
        ).then(res => {
            if (res.status === 200) {
                dispatch(emptyGoals())
                dispatch(forEachGoal(currentUser))
                const newArray = getInter.filter(f => f.id !== id)
                setGetInter(newArray)
            }
        }).catch(e => console.log(e))


    }
    return (
        <div>

            {
                !isEmpty(getInter) && (
                    <ul>
                        {
                            getInter.map((f,index) => (

                                <li key={index}>
                                    <div className="objectifInter_list_obj">
                                        <p className="objectifInter_list_obj_number">{index + 1} .</p>
                                        <p className="objectifInter_list_obj_text_distance">{f.name}</p>
                                        <p className="objectifInter_list_obj_text_date">{moment(f.date).format('ll')}</p>
                                        <IonIcon icon={closeOutline}
                                                 onClick={() => handleDelete(f.id, f.goalId)}/>
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
                )
            }

        </div>
    );
};

export default ModifyInter;