import React, {useEffect, useState} from 'react';
import {IonAlert} from "@ionic/react";
import {buyPackage} from "../App/Profil/shops";
import {Capacitor} from "@capacitor/core";
import {useSelector} from "react-redux";

const ModalNotVip = ({showAlert,setShowAlert}) => {
    const platform = Capacitor.getPlatform();
    const isVip = useSelector(state => state.isVIP)


    return (
        <IonAlert
            mode={platform === 'ios' ? "ios" : "md"}
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            message="La période d'essai est expirée, abonne-toi pour continuer ton plan d'entraînement"
            buttons={[
                {
                    text: "Annuler",

                },
                {
                    text: "S'abonner (7.99€/mois)",
                    handler: () => {
                        buyPackage('flit1m')
                    }
                }
            ]}
        />
    );
};

export default ModalNotVip;