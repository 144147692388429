import {
  IonPage,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonContent,
  IonTitle,
  IonText,
  IonButton,
} from "@ionic/react";
import React from "react";
import "./paiements.css";

const Paiement = () => {
  return (
    <IonPage>
      <IonHeader  class="ion-no-border">
        <IonToolbar mode="ios" className="app_toolbar">
          <IonButtons>
            <IonBackButton
              defaultHref="/settings"
              text=""
              className="app_backButtons subscribe_header_text"
            />
          </IonButtons>
          <IonTitle className="app_header_title">Abonnements</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="app_content">
        <div className="subscribe">
          <div className="susbcribe_box">
            <div className="subscribe_box_title_content">
              <IonText>
                <h4 className="susbcribe_box_title">Abonnement Flit Sport</h4>
              </IonText>
              <IonText>
                <p className="susbcribe_box_title_subtitle">1 mois gratuit</p>
              </IonText>
            </div>
            <div className="susbcribe_box_info">
              <IonText>
                <p className="subscribe_subtitle">Inclus:</p>
              </IonText>
              <ul className="subscribe_box_list">
                <li className="subscribe_box_list_items">Plans sur mesure</li>
                <li className="subscribe_box_list_items">Séances variées</li>
                <li className="subscribe_box_list_items">
                  Progression Optimale
                </li>
              </ul>
            </div>
            <IonButton className="subscribe_button">4.99€</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Paiement;
