import axios from "axios";
import {updateDisponibility} from "../../redux/slices/disponibility/disponibility";

export const GetDisponibility = (currentUser)=> async (dispatch) => {
    await axios
        .post(
            `${process.env.REACT_APP_STAGING_V2}/disponibility/foreach`,
            {
                debug: false,
                filter: [
                    {
                        field: "userId",
                        comparator: "==",
                        operand: [[currentUser.id]],
                    },
                ],
                fields: [],
            },
            {
                headers: {
                    Authorization: currentUser.token,
                },
            }
        )
        .then((res) => {
            if (res.status === 200 ) {
                dispatch(updateDisponibility({id: res.data.Data[0].id, disponibility: res.data.Data[0]}))

            }
        })
        .catch((err) => {
            console.log(err)
        });
}