import {createSlice} from '@reduxjs/toolkit';

export const trainingDataSlice = createSlice({
        name: 'training',
        initialState: [],
        reducers: {
            getTraining: (state, action) => (

                [...state, ...action.payload]
            ),
            emptyTraining: (state, action) => {
                return state = []
            }
        }
    })
;

export const countSlice = createSlice({
    name: 'count',
    initialState: {
        value: 0
    },
    reducers: {
        defaultValue: state => {
            state.value = 0
        },
        increment: state => {
            state.value += 1;
        },
        decrement: state => {

            state.value -= 1


        },

    },
});


export const trainingShowModalSlice = createSlice({
    name: 'showModal',
    initialState: {
        showModal: false,
        afterRecalcul: false
    },
    reducers: {
        getShowModal: (state, action) => ({...state, ...action.payload}),
    },
});

export const newGoalsSlice = createSlice({
    name: 'newGoal',
    initialState: {
        newGoal: false
    },
    reducers: {
        getnewGoal: (state, action) => ({...state, ...action.payload}),
    },
});


export const {getTraining, emptyTraining} = trainingDataSlice.actions;
export const {increment, decrement, defaultValue} = countSlice.actions
export const {getShowModal} = trainingShowModalSlice.actions
export const {getnewGoal} = newGoalsSlice.actions
