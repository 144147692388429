import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../../../Utils/Utils";


export const goalSlice = createSlice({
    name: "getGoal",
    initialState:[],
    reducers: {
        getGoal: (state, action) => {


              return state= [...state ,action.payload]

        },
        emptyGoals: (state,action)=> {
            return state=[]
        }

    },
});




export const {getGoal,emptyGoals} = goalSlice.actions;
