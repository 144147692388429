import { createSlice } from '@reduxjs/toolkit';

export const registerUserSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    addUser: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { addUser, updateUser } = registerUserSlice.actions;
