import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonText,
    IonIcon, isPlatform, IonAlert
} from "@ionic/react";
import {chevronDown, chevronForwardOutline} from "ionicons/icons";
import "./settings.css";
import {Link} from "react-router-dom";
import {useState} from "react";
import UpdatePassword from "./UpdatePassword/UpdatePassword";
import {DocumentViewer} from "@awesome-cordova-plugins/document-viewer";
import CGVDoc from "../../../../assets/CGV.pdf";
import {useHistory} from "react-router";
import {Browser} from "@capacitor/browser";
import {useSelector} from "react-redux";
import axios from "axios";
import {Capacitor} from "@capacitor/core";
import {restorePurchases} from "../shops";

const Settings = () => {

    const [showUpdatePassword, setShowUpdatePassword] = useState(false)
    const history = useHistory()
    const [showInputName, setShowInputName] = useState(false)
    const currentUser = useSelector(state => state.currentUser)


    // delete account function
    const deleteAccount = () => {
        axios.post(`${process.env.REACT_APP_STAGING_V2}/user/delete`, {
            debug: false,
            data: {
                email: currentUser.email
            },
            args: {}
        }, {

            headers: {
                authorization: currentUser.token,
            },

        }).then(res => {
                if (res.status === 200) {
                    history.push("/connexion")
                }
            }
        ).catch(e => {
            console.log(e)
        })
    }


    return (
        <IonPage>
            <IonHeader class="ion-no-border" mode="ios">
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        <IonBackButton
                            defaultHref="/tab4"
                            text={""}
                            className="headerBackButton"
                        >
                        </IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <div className="settings">
                    <div className="settings_account">
                        <IonText>
                            <h4 className="settings_title">Mon compte</h4>
                        </IonText>
                        <ul className="settings_account_list">

                            <li onClick={() => setShowUpdatePassword(!showUpdatePassword)}
                                className="settings_account_list_items">
                                <p>Modifier mon mot de passe</p>{" "}
                                <IonIcon icon={showUpdatePassword ? chevronDown : chevronForwardOutline}/>

                            </li>
                            {
                                showUpdatePassword ? <UpdatePassword setUpdatePassword={setShowUpdatePassword}/> : ""
                            }
                            <li onClick={() => restorePurchases()}
                                className="settings_account_list_items">
                                <p>Restaurer mes achats</p>{" "}
                                <IonIcon icon={chevronForwardOutline}/>

                            </li>

                            <li onClick={() => setShowInputName(!showInputName)}
                                className="settings_account_list_items ">
                                <p style={{color: "red"}}>Supprimer mon compte</p>{" "}
                                <IonIcon icon={chevronForwardOutline}/>
                            </li>
                            <IonAlert
                                mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                                isOpen={showInputName}
                                onDidDismiss={() => setShowInputName(false)}
                                header="Attention !"
                                message="Tu es sûr ? Toutes tes données de progression seront perdues."
                                buttons={[{
                                    text: "Annuler",
                                    role: "cancel"
                                }, {
                                    text: "Supprimer",
                                    handler: () => {
                                        deleteAccount()
                                    }
                                }]}
                            />
                        </ul>
                    </div>

                    <div className="settings_about">
                        <IonText>
                            <h4 className="settings_title">À propos</h4>
                        </IonText>
                        <ul className="settings_account_list">
                            <li onClick={() => {
                                Browser.open(({url: "https://flit.run/politiques-de-confidentialite/"}))
                            }} className="settings_account_list_items">
                                <p>Politique de Confidentialité</p>{" "}
                                <IonIcon icon={chevronForwardOutline}/>
                            </li>
                            <li onClick={() => {
                                Browser.open(({url: "https://flit.run/conditions-generales/"}))
                            }} className="settings_account_list_items ">
                                <p>Conditions de ventes et d'utilisations</p> <IonIcon icon={chevronForwardOutline}/>

                            </li>

                        </ul>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Settings;

